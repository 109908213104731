import React from 'react'

import Link from 'next/link'
import {useRouter} from 'next/router'
import {RemixiconReactIconComponentType} from 'remixicon-react'
import {media} from 'styled-bootstrap-grid'
import styled, {css} from 'styled-components'
import {palette, ifProp, theme} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'

export interface NavItem {
  icon: RemixiconReactIconComponentType
  label: string
  pagePath: string
}

interface Props {
  navItems: NavItem[]
}

interface ItemProps {
  active?: boolean
}

const Wrapper = styled.div`
  position: relative;
  display: none;
  background-color: ${palette('blue')};
  border-right: 4px solid ${palette('green')};
  padding: 120px 0;
  min-height: 100vh;
  width: 300px;
  min-width: 300px;

  ${media.md`
    display: block;
  `}
`

const ItemsWrapper = styled.div`
  position: fixed;
  top: ${theme('headerHeight')};
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ItemLabel = styled.span`
  font-size: 1.125rem;
  font-weight: 500;
  color: ${palette('ui40Solid')};
  transition: color 0.15s;
`

const Item = styled.div<ItemProps>`
  padding: 20px 40px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: ${styledTheme.cursor.pointer};

  .remixicon-icon {
    margin-right: 32px;
    height: 24px;
    width: 24px;
    fill: ${palette('ui60Solid')};
  }

  ${ifProp(
    {active: true},
    css`
      .remixicon-icon {
        fill: ${palette('green')};
      }

      ${ItemLabel} {
        color: ${palette('white')} !important;
      }
    `,
    '',
  )}

  &:hover {
    ${ItemLabel} {
      color: ${palette('white')};
    }

    .remixicon-icon {
      fill: ${palette('green')};
    }
  }
`

export default function MyAccountNav({navItems}: Props): JSX.Element {
  const {pathname} = useRouter()

  return (
    <Wrapper>
      <ItemsWrapper>
        {navItems.map(({icon: Icon, label, pagePath}: NavItem) => (
          <Link key={pagePath} href={pagePath}>
            <Item active={pagePath === pathname}>
              <Icon />
              <ItemLabel>{label}</ItemLabel>
            </Item>
          </Link>
        ))}
      </ItemsWrapper>
    </Wrapper>
  )
}
